/* =================================
  Element Styles
==================================== */

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
@font-face {
  font-family: 'Belleza-Regular';
  src: url("./assets/fonts/Belleza Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'DancingScript-Bold';
  src: url("./assets/fonts/Dancing Script Bold.ttf") format("truetype");
}
@font-face {
  font-family: 'DancingScript-Regular';
  src: url("./assets/fonts/Dancing Script Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'Raleway-Bold';
  src: url("./assets/fonts/Raleway Bold.ttf") format("truetype");
}
@font-face {
  font-family: 'Raleway-ExtraBold';
  src: url("./assets/fonts/Raleway ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: 'Raleway-Medium';
  src: url("./assets/fonts/Raleway Medium.ttf") format("truetype");
}
@font-face {
  font-family: 'Raleway-Regular';
  src: url("./assets/fonts/Raleway Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'Dynamo-Regular';
  src: url("./assets/fonts/Dynamo Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'MrDafoe-Regular';
  src: url("./assets/fonts/MrDafoe-Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'Lucidity-Condensed';
  src: url("./assets/fonts/lucidity-condensed-webfont.ttf") format("truetype");
}
@font-face {
  font-family: 'Dynamo';
  src: url("./assets/fonts/Dynamo.ttf") format("truetype");
}
@font-face {
  font-family: 'BroadwayEngravedBT-Regular';
  src: url("./assets/fonts/BroadwayEngravedBT-Regular.otf") format("truetype");
}
@font-face {
  font-family: 'DynamoEF-BoldShadow';
  src: url("./assets/fonts/DynamoEF-BoldShadow.otf") format("truetype");
}
@font-face {
  font-family: 'Gagalin-Regular';
  src: url("./assets/fonts/Gagalin.otf") format("truetype");
}
@font-face {
  font-family: 'Pirou-Regular';
  src: url("./assets/fonts/Pirou.otf") format("truetype");
}
@font-face {
  font-family: 'GreatVibes-Regular';
  src: url("./assets/fonts/Great Vibes Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'LDFComicSans';
  src: url("./assets/fonts/LDFComicSans.ttf") format("truetype");
}
@font-face {
  font-family: 'OPTICopperplate';
  src: url("./assets/fonts/OPTICopperplate.otf") format("truetype");
}
@font-face {
  font-family: 'CopperplateCC';
  src: url("./assets/fonts/CopperplateCC.ttf") format("truetype");
}
@font-face {
  font-family: 'LibreBaskerville-Bold';
  src: url("./assets/fonts/Libre Baskerville Bold.ttf") format("truetype");
}
@font-face {
  font-family: 'LibreBaskerville-Italic';
  src: url("./assets/fonts/Libre Baskerville Italic.ttf") format("truetype");
}
@font-face {
  font-family: 'LibreBaskerville-Regular';
  src: url("./assets/fonts/Libre Baskerville.ttf") format("truetype");
}
@font-face {
  font-family: 'Nickainley-Normal';
  src: url("./assets/fonts/Nickainley Normal.ttf") format("truetype");
}

@font-face {
  font-family: 'Playlist-Script';
  src: url("./assets/fonts/Playlist Script.otf") format("truetype");
}
@font-face {
  font-family: 'Playlist-Caps';
  src: url("./assets/fonts/Playlist Caps.otf") format("truetype");
}
@font-face {
  font-family: 'LeagueSpartan-Bold';
  src: url("./assets/fonts/LeagueSpartan-Bold.ttf") format("truetype");
}
@font-face {
  font-family: 'LeagueSpartan-Regular';
  src: url("./assets/fonts/LeagueSpartan-Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'AbhayaLibre-Regular';
  src: url("./assets/fonts/Abhaya Libre Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'AbhayaLibre-Medium';
  src: url("./assets/fonts/Abhaya Libre Medium.ttf") format("truetype");
}

@font-face {
  font-family: 'AbhayaLibre-Bold';
  src: url("./assets/fonts/Abhaya Libre Bold.ttf") format("truetype");
}
@font-face {
  font-family: 'AbhayaLibre-ExtraBold';
  src: url("./assets/fonts/Abhaya Libre ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: 'Alice-Regular';
  src: url("./assets/fonts/Alice-Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'Aleo-Bold';
  src: url("./assets/fonts/Aleo Bold.ttf") format("truetype");
}
@font-face {
  font-family: 'Aleo-Regular';
  src: url("./assets/fonts/Aleo Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'Aleo-Medium';
  src: url("./assets/fonts/Aleo Medium.ttf") format("truetype");
}

@font-face {
  font-family: 'Aleo-ExtraBold';
  src: url("./assets/fonts/Aleo ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: 'Aleo-Light';
  src: url("./assets/fonts/Aleo Light.ttf") format("truetype");
}
@font-face {
  font-family: 'Aleo-Italic';
  src: url("./assets/fonts/Aleo Italic.ttf") format("truetype");
}
@font-face {
  font-family: 'TallowSansTC-Regular';
  src: url("./assets/fonts/Tallow Sans TC Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'TallowTC-Regular';
  src: url("./assets/fonts/Tallow TC Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'Monoton-Regular';
  src: url("./assets/fonts/Monoton.ttf") format("truetype");
}
@font-face {
  font-family: 'NovaCut';
  src: url("./assets/fonts/Nova Cut.ttf") format("truetype");
}
@font-face {
  font-family: 'RobotoMono-Light';
  src: url("./assets/fonts/Roboto Mono Light.ttf") format("truetype");
}
@font-face {
  font-family: 'RobotoMono-Medium';
  src: url("./assets/fonts/Roboto Mono Medium.ttf") format("truetype");
}
@font-face {
  font-family: 'Sansita-Italic';
  src: url("./assets/fonts/Sansita Italic.ttf") format("truetype");
}
@font-face {
  font-family: 'Sansita-Regular';
  src: url("./assets/fonts/Sansita Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'SpicyRice-Regular';
  src: url("./assets/fonts/Spicy Rice.ttf") format("truetype");
}
@font-face {
  font-family: 'SuezOne-Regular';
  src: url("./assets/fonts/SuezOne-Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'Sansita-Italic';
  src: url("./assets/fonts/Sansita Italic.ttf") format("truetype");
}
@font-face {
  font-family: 'GlacialIndifference-Regular';
  src: url("./assets/fonts/GlacialIndifference-Regular.otf") format("truetype");
}
@font-face {
  font-family: 'GlacialIndifference-Bold';
  src: url("./assets/fonts/GlacialIndifference-Bold.otf") format("truetype");
}
@font-face {
  font-family: 'NixieOne-Regular';
  src: url("./assets/fonts/NixieOne-Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'AllPony';
  src: url("./assets/fonts/AllPony.ttf") format("truetype");
}
@font-face {
  font-family: 'Amsterdam-Four_ttf';
  src: url("./assets/fonts/Amsterdam-Four_ttf.ttf") format("truetype");
}
@font-face {
  font-family: 'Gliker-Regular';
  src: url("./assets/fonts/Gliker-Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'HarlowSolidPlain';
  src: url("./assets/fonts/HarlowSolidPlain.ttf") format("truetype");
}
@font-face {
  font-family: 'NF-Ananias-Bold';
  src: url("./assets/fonts/NF-Ananias-Bold.ttf") format("truetype");
}
@font-face {
  font-family: 'NF-Ananias-Regular';
  src: url("./assets/fonts/NF-Ananias-Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'AmbiguityTradition-Inline';
  src: url("./assets/fonts/AmbiguityTradition-Inline.ttf") format("truetype");
}


  

body {
  font-family: 'Lato', sans-serif;
  color: #4c4c4c;
  background: #f8fdf3;
}

.App {
  width: 100%;
  max-width: 900px;
  margin: 2.5em auto;
  border-radius: .35em;
  background: #fcfcfc;
  overflow: hidden;
  -webkit-box-shadow: 0 0 26px rgba(0, 0, 0, .13);
  box-shadow: 0 0 26px rgba(0, 0, 0, .13);
}

h1,
p,
form button {
  color: white;
}

h1 {
  font-family: 'Courgette', cursive;
  text-shadow: 0 1px 0 rgba(0, 0, 0, .4);
  line-height: .65;
  margin-top: .5em;
  margin-bottom: 0;
}

h2 {
  margin-top: 0;
  margin-bottom: 0.35em;
}

p {
  font-size: 1.1em;
  text-shadow: 0 1px 0 rgba(0, 0, 0, .25);
  color: black;
}

form {
  width: 60%;
  background: white;
  display: inline-block;
  overflow: hidden;
  display: -ms-flexbox;
  display: flex;
  border-radius: .2em;
  border: solid 4px white;
  -webkit-box-shadow: 0 1px 14px rgba(0, 0, 0, .12);
  box-shadow: 0 1px 14px rgba(0, 0, 0, .12);
}

form button {
  padding: 0 1em;
  font-size: 1em;
  background: #7bcbc4;
  border-radius: .2em;
}

ul {
  list-style: none;
  padding: 0;
  margin: 2em 0 1em;
}

ul li {
  padding: 1em;
  background: #fff;
  border-radius: .2em;
  border: solid 1px rgba(88, 183, 205, .15);
  border-bottom: solid 2px rgba(88, 183, 205, .15);
  position: relative;
}

button {
  cursor: pointer;
}
.event-details button {
  font-size: 1em;
  background: #7bcbc4;
  border-radius: 0.2em;
  color: white;
  padding: 12px 33px;
}

button {
  border: none;
  outline: none;
}

header {
  text-align: center;
  background-size: cover;
}
img {
  width: 300px;
  height: auto;
  margin: 0 auto;
  display: block;
}

header input {
  padding: 12px;
  font-size: 1.15em;
  width: 100%;
}

li span,
li input[type=text] {
  color: #707070;
  font-size: 1.3em;
  margin-bottom: .3em;
}

li input[type=text] {
  padding: .2em;
  width: 95%;
  border: 1px dotted rgba(0, 0, 0, .2);
}

input[type="checkbox"] {
  margin-right: 5px;
}

li label {
  font-size: .9em;
  display: block;
  color: rgba(112, 112, 112, .55);
}

li span,
li input[type=text]:first-child {
  display: block;
}

li button {
  font-size: .78em;
  margin-top: 1.65em;
  margin-right: .4em;
  border-radius: .3em;
  padding: .4em .6em;
  color: white;
  background: #58b7cd;
}

li button:last-child {
  background: rgba(88, 183, 205, .5);
}

div > input:last-child {
  font-size: 1em;
  margin-left: 6px;
}

div > label {
  color: #767676;
}

.counter {
  display: block;
}

tr {
  color: rgba(118, 118, 118, .75);
}

td:last-child {
  font-weight: bold;
  font-size: 1.16em;
  color: rgb(118, 118, 118);
}
/* pending */

.pending {
  border-color: rgba(210, 210, 210, .5);
}

.pending span {
  color: rgb(210, 210, 210);
}
/* responded */

.responded {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  border-color: rgba(88, 183, 205, .9);
}

.responded label {
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  color: rgba(88, 183, 205, 1);
}
.single-column-form {
  width: 100%;
  max-width: 400px; /* Adjust the maximum width as needed */
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 8px;
}

label {
  font-weight: bold;
  width: 40%; /* Adjust the width as needed */
}

input,
textarea {
  flex: 1;
  padding: 3px;
  margin-left: 8px;
}

button[type="submit"] {
  width: 100%;
}

/* =================================
  Media Queries
==================================== */

@media (max-width: 768px) {
  header {
    padding: 1.25em;
  }
  h1 {
    font-size: 3.6em;
    margin: .3em 0 0;
  }
  ul li {
    margin-bottom: 1em;
  }
  form {
    width: 95%;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: auto;
    margin-top: 2.5em;
  }
  form button {
    padding: 12px 0;
    margin-top: .5em;
  }
  form input {
    font-size: 1em;
    padding-left: 10px;
  }
  .wrapper {
    margin: 0;
  }
  .main {
    padding: 2em 1em .75em;
  }
  .counter {
    margin-top: 1.65em;
    padding-top: 1.5em;
    border-top: 1px dotted rgba(0, 0, 0, .15);
  }
  tr {
    display: inline-block;
    padding: 0 12px 0 0;
  }
}
.container {
  display: flex;
  flex-wrap: wrap;
}

.column {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}
.close-button {
  position: absolute;
  right: 10px;
  top: 30px;
}
.selected {
  background-color: #e7e8e8;
} 
.labelFood {
  margin-bottom: 0.5em;
}

/* Media query for mobile devices (adjust the breakpoint as needed) */

@media (min-width: 769px) {
  header {
    height: 280px;
    background-position: 0 -95px;
  }

  div > label {
    margin-top: 12px;
  }
  h1 {
    font-size: 5.8em;
  }
  h2 {
    font-size: 1.95em;
  }
  form {
    margin: 4.15em auto 0;
    z-index: 3000;
    position: relative;
  }
  .wrapper {
    width: 90%;
  }
  .main {
    padding: 3.8em 1.5em .75em;
    position: relative;
    z-index: 10;
  }
  .counter {
    float: right;
    text-align: right;
    margin-right: 1.25%;
  }

  .main > div {
    float: left;
    margin-left: 1.25%;
    margin-bottom: 1.5em;
  }
  ul {
    display: -ms-flexbox;
    display: flex;
    clear: both;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-top: 1.25em;
  }
  ul li {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 47.5%;
    flex-basis: 47.5%;
    margin: 0 1.25% 1em;
  }
 
}

@media (min-width: 880px) {
  ul li {
    -ms-flex-preferred-size: 20%;
    flex-basis: 20%;
  }
}
@media (min-width: 768px) {
  .modal-container {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .modal-container form {
    width: 50%; /* Adjust the width as needed */
  }
}
@media (max-width: 480px) {
 .form-row {
  display: block;
 }
 div > input:last-child {
  font-size: 1em;
  margin-left: 0px;
  padding: 0;
  width: 100%;
}
textarea {
  padding: 0px;
  margin-left: 0px;
  width: 100%
}

}
